


.marquee {
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 40%;
    right:100%;
    transform: translate(-50%, -50%);
    width: 55%;
    
    padding: 0px;
    
    
}

.marquee span {
    display: inline-block;
    animation: marquee-text 10s linear infinite;
}

@keyframes marquee-text {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}
.main{
  font-size:32px;
  background: #ad0000;/*linear-gradient(to right, green, blue,red); /* Gradient color for text */
  -webkit-background-clip: text; /* Clip text to background gradient */
  color: transparent;
  font-weight:bold;
  font-family: 'Courier New', monospace;
  
}
.tiny{
  font-size:12px;
  color:#4585df;
}


