/* Links & Buttons */
a {
	text-decoration: none;
	color: var(--primary-color);
	transition: all ease 0.25s;
	&:hover {
		color: #222222;
	}
}

.light-links {
	.button {
		border: 2px solid #1396d3;
		padding: 0.25em 1em;
	}

	a {
		color: #1396d3;
		&:hover {
			color: #ffffff;
		}
	}
}

button {
	white-space: nowrap;
	font-size: 1rem;
	border-radius: 0;
	padding: 0.55rem 1rem;
	margin: 0.25rem;
	border: 2px solid #ffffff;
	color: #ffffff;
	background-color: transparent;
	transition: all ease 0.25s;

	&:hover {
		cursor: pointer;
		color: var(--primary-color);
		background-color: #f8f8f8;
	}
	&[disabled] {
		opacity: 0.25;
		cursor: not-allowed;
		&:hover {
			color: #ffffff;
			background-color: transparent;
		}
	}
	&.textOnly {
		font-size: inherit;
		border: none;
		padding: 0;
		color: #ffffff;
		background: transparent;
		&.primary {
			color: var(--primary-color);
			&:hover {
				color: var(--secondary-color);
			}
		}
		&.secondary {
			color: var(--secondary-color);
			&:hover {
				color: var(--primary-color);
			}
		}
		&:hover {
			color: var(--primary-color);
		}
	}
	&.altBtn {
		border-color:#ad0000;
		color: #ad0000;
		&:hover {
			background-color: #ad0000;
			color: #ffffff;
		}
		&[disabled]:hover {
			border-color: var(--primary-color);
			color: var(--primary-color);
		}
	}
	&.primaryBtn {
		background-color: red;
		color: #ffffff;
		border-color: red;
		&:hover {
			border-color: white;
			background-color: #efefef;
			color: red;
		}
		&[disabled]:hover {
			background-color: var(--primary-color);
			color: #ffffff;
			border-color: var(--primary-color);
		}
	}
	&.play-button {
		background-color: green;
		border-color: green;
		&:hover {
			background-color: darkgreen;
			border-color: darkgreen;
			color: white;
		}
	}
	&.pause-button {
		background-color: red;
		border-color: red;
		&:hover {
			background-color: darkred;
			border-color: darkred;
			color: white;
		}
	}
}
