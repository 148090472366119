.bingo-p-board {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 2px;
  max-width: 200px;
  margin: left;
  justify-content: space-between;
}

.bingo-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  /* border: 1px solid #640404; */
  background-color: transparent;
  font-size: 1.2rem;
  border-radius: 5px;
  animation-duration: 2s; /* Animation duration */
  animation-iteration-count: infinite; /* Infinite animation */
}

