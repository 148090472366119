
.board-container {
    display: flex;
    align-items: flex-start;
    /* justify-content: start; */
    
    height:100%;
    width:100%;
    gap: 5px 5px;
    flex-wrap: wrap;
    padding: 25px;


}


.card-display {
    /* padding: 12px; */
    cursor: pointer;
    /* width: 30%; */
    width: 120px;
    height: 120px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-around;
    margin: 0 auto;
    /* background-color: maroon; */
    /* box-shadow: 4px 3px 6px 4px rgba(255, 255, 255, 0.4); */
    border-radius: 50%;
}
.card-content {
    text-align: center;
    border-radius: 100%;
    background: white;
    position: relative;
    min-width: 50px;
    min-width: 2vw;
    padding: 28%;
    box-shadow: inset -0.5rem -0.5rem 2rem 0 rgba(255, 255, 255, 0.16), 0 0 0 0.25rem white;
    background: radial-gradient(circle at 20% 20%, rgb(255, 255, 255), white);
}
.card-detail-content {
    justify-content: center;
    align-items: center;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: inline-flex;
    flex-direction: column;
}
.card-selected {
    background-color:#1a1a1d;
    border-radius: 100%;
    color: white;
}
.card-id {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    display: block;
    width: 100%;
}
.card-name {
    line-height: 1;
    display: block;
    width: 100%;
    font-weight: bold;
}